<template>
  <div class="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
    <div class="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96" aria-hidden="true" />
    <div class="mx-auto max-w-7xl px-6 py-20 sm:py-30 lg:py-24 lg:px-8">
      <div class="w-fit mx-auto max-w-2xl text-center lg:text-left lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6">
        <h1 class="max-w-2xl text-4xl font-bold leading-tight tracking-tight text-gray-900 sm:text-6xl lg:self-end">
          <slot name="title" />
        </h1>
        <div class="mt-6 max-w-xl lg:mt-0 lg:order-last lg:self-start">
          <p class="text-lg leading-8 text-gray-600">
            <slot name="description" />
          </p>
          <div class="mt-10 flex justify-center items-center gap-x-6 lg:justify-start">
            <slot name="buttons" />
          </div>
        </div>
        <img
          src="https://images.unsplash.com/photo-1567532900872-f4e906cbf06a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1280&q=80" alt=""
          class="mt-10 mx-auto aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:row-span-2 lg:mt-0 lg:max-w-none"
        >
      </div>
    </div>
    <div class="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
  </div>
</template>
